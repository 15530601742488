import {
  EmailShareButton,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  FacebookShareCount,
  TwitterIcon,
  LinkedinIcon,
  TelegramIcon,
  EmailIcon,
} from "react-share";


import './App.css';
import p1 from './images/p1.png'
import p2 from './images/p2.png'
import p3 from './images/p3.png'
import p4 from './images/p4.png'
import p5 from './images/p5.png'
import p6 from './images/p6.png'
import p7 from './images/p7.png'
import p8 from './images/p8.png'
import p9 from './images/p9.png'
import p10 from './images/p10.png'
import p11 from './images/p11.png'
import hasabIcon from './images/hasabIcon.png'
import pantype from './images/pantype.png'
import youtube from './images/youtube.png'
import github from './images/github.png'
import mail from './images/mail.png'
import shiftting from './images/shiftting.png'
import appstore from './images/appstore.png';
import googleplay from './images/googleplay.png'

// import p1 from './images/s1.png'
// import p2 from './images/s2.png'
// import p3 from './images/s3.png'
// import p4 from './images/s4.png'
// import p5 from './images/s5.png'
// import p6 from './images/s6.png'
// import p7 from './images/s7.png'
// import p8 from './images/p8.png'
// import p9 from './images/p9.png'
// import p10 from './images/p10.png'
// import p11 from './images/p11.png'

const promo = [
  { title: "/ሓሳቦች", subtitle: "ጓደኞች /ሓሳቦችን ሲለጥፉ ማሳወቂያ ይደርሰዎታል", img: p6, bottomtitle: "Share ideas or just say something" },
  { title: "/ውይይቶች ", subtitle: "ውይይቶች በቡድን ወይም በግል", img: p2, bottomtitle: "Discussions in groups or private" },
  { title: "/ጓደኞች ማሳያ", subtitle: "የጓደኞች ሰንጠረዥ ማሳያ ዝርዝር", img: p3, bottomtitle: "See all your friends" },
  { title: "/ጓደኞች ማሳያ", subtitle: "የጓደኞች ሰንጠረዥ ማሳያ ዝርዝር", img: p4, bottomtitle: "See All friends" },
  { title: "/ምርጫ", subtitle: "Dark እና light ቅንብሮች እና የቋንቋ ምርጫ", img: p5, bottomtitle: "Mode and language Settings" },

  { title: "/ተጨማሪ", subtitle: "ተጨማሪ አማራጮች ምሳሌ", img: p7, bottomtitle: "More example" },
  { title: "/ተጨማሪ", subtitle: "ተጨማሪ አማራጮች ምሳሌ", img: p8, bottomtitle: "More example" },
  { title: "/መጻፍ", subtitle: "በ press, ​​longPress, pan, type ማድረግ", img: p9, bottomtitle: "Type by press, long press, or pan." },
  { title: "/መጻፍ", subtitle: "በ press, ​​longPress, pan, type ማድረግ", img: p11, bottomtitle: "Dark Mode" },
  { title: "/ቁጥሮች", subtitle: "ቁጥሮች", img: p10, bottomtitle: "Ethiopic Numbers" },
  { title: "/ሌሎች ምን አሉ", subtitle: "የሌሎች ሰዎች ሀሳቦች ምን እንደሆኑ ማየት ይችላሉ", img: p1, bottomtitle: "Discover" },


]

function App() {
  return (
    <div className="App" id="home">
      <div className="header">
        <div className="logo">
          <a href="#home"><img className="logoimage" alt="logo" src={hasabIcon} /></a>
        </div>
        <div className="menu">
          <div><a href="#howto">How to</a></div>
          <div><a href="#about">About Us</a></div>
          <div><a href="#comment">Contact Us</a></div>
        </div>
      </div>
      <div>
      </div>
      <div className="intro">/ወደ /ሓሳብ እንኳን ደህና መጡ
        <p>Welcome To Hasab</p>
        <a href="https://apps.apple.com/us/app/hasab/id1585259896"> <img id="appstore" src={appstore} alt="appstore" /></a>
        <a href='https://play.google.com/store/apps/details?id=org.mulat.hasab.android'><img id="googleplay" alt='googleplay' src={googleplay}/></a>
      </div>
      <div class="screenshots">
        <p>ምሳሌዎች Screen Shots</p>
        <div className="promo">
          {promo.map((m, i) => <div key={m.title + i}>
            <p className="title">{m.title}</p>
            <p className="subtitle">{m.subtitle}</p>
            <img alt="img" className="image" src={m.img} />
            <div className="bottomtitle">{m.bottomtitle}</div>
          </div>)}
        </div>
      </ div>

      <div>
        <div className="howto" id="howto">
          <img alt="shiffting" src={shiftting} />
          <p>Besides traditional typing, you can also type with panning. Try it. Example. press for: ለ,  fast pan right for: ሉ, slow pan right for: ሊ, fast pan down for: ላ, slow pan down for: ሌ, slow or fast pan left for: ል , fast pan up for: ሎ, slow pan up for:  ሏ (currently only available for iOS) (more instructional guides coming soon.</p>
          <img alt="howto" src={pantype}></img>
        </div>
      </div>


      <div className="donatecontainer" id="about">
        <p className="donatemessage">/ሠላም, /ፋሲል እባላለሁ. /በዓሁን ላይ, /ሙላትን የምሰራው ብቻዬን ነው. /ሙላት በጣም የማምንበት ፕሮጀኽት ነው. /የሚቻለዎ ከሆነ, ያግዙኝ. በገንዘብ, በሃሳብ, ኮድ በማድረግ, ወዘተ...ከታች በሚገኙት ሊንኮች. /እግዚኣብሄር ዪስጥልኝ.
          Hello my name is Fasil. I currently work on Mulat project solo. If you are able to help with donations, ideas, code contribution etc.... please connect on the links below. Thanks</p>

        <hr />
        <div>
          <div className="paypal">
            <form action="https://www.paypal.com/donate" method="post" target="_top">
              <input type="hidden" name="cmd" value="_donations" />
              <input type="hidden" name="business" value="fasilx@yahoo.com" />
              <input type="hidden" name="item_name" value="non-profit software development" />
              <input type="hidden" name="currency_code" value="USD" />
              <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
              <img alt="" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
            </form>
          </div>
          <div className="social">
            <a href="https://www.youtube.com/channel/UCAzphuUVjZEhZd62ckhIq3Q">
              <img className="icon" src={youtube} alt="youtube" />
            </a>
            <a href="mailto:mulat.derassi@gmail.com?subject='About Hasab'">
              <img className="icon" alt="mail" src={mail} />
            </a>
            <a href="https://github.com/fasilx">
              <img className="icon" src={github} alt="github" />
            </a>
          </div>
        </div>
        <hr />
      </div>

      <div className="comment" id="comment">
        Email Us: mulat.derassi@gmail.com
        <form className="form" action="mailto:mulat.derassi@gmail.com?subject=About Hasab" method="post" enctype="text/plain">
          <label>Name:</label>
          <input type="text" name="name" />
          <label>Email:</label>
          <input type="text" name="mail" />
          <label>Comments and Questions</label>
          <input type="text" name="comment" size="50" />
          <input type="submit" value="Send" />
          <input type="reset" value="Reset" />
        </form>
      </div>

      <div className="footer" id="footer">
        <div className="left">
          &copy; 2020 Mulat.org
          <div className="copyright">
            <hr></hr>
            /የ /ሙላት ሀሳብ ዕና ሞራል የመጣው
            <a href="https://youtu.be/jQgMLR84hck?list=PLojbDl2YkdESj-v6Dyas2HHZQ2rwgPHIz&t=351">
              &nbsp; ከዕዚህ የ /ዶር. /ዐብይ /ዐህመድ ንግግር ነው.
            </a>
          </div>
        </div>
        <div className="share">
          <FacebookShareButton url="https://hasab.mulat.org" quote="Social Network for Ethiopia and Eritrea">
            <FacebookIcon size={40} round />
          </FacebookShareButton>
          <TwitterShareButton
            url="https://hasab.mulat.org"
            via="mulat"
            title="Social Network for Ethiopia and Eritrea"
            hashtags={['mulat', 'derassi']}
            related={["@mulat05465071", "@ffikreab"]}
          >
            <TwitterIcon size={40} round />
          </TwitterShareButton>
          <LinkedinShareButton
            url="https://mulat.org"
            title="mulat.org"
            summary="share"
            source="https://mulat.org">
            <LinkedinIcon size={40} round />
          </LinkedinShareButton>
          <TelegramShareButton url="https://hasab.mulat.org"
            title="Social Network for Ethiopia and Eritrea">
            <TelegramIcon size={40} round />
          </TelegramShareButton>
        </div>
      </div>
    </div>
  );
}

export default App;
